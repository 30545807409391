import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    name: 'Root',
    redirect: { name: 'Analysis' },
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',

    meta: {
      hidden: true,
      title: t('router.login')
    }
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal-center',
    name: 'Personal',
    meta: {
      title: t('router.personal'),
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: 'personal-center',
        component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
        name: 'PersonalCenter',
        meta: {
          title: t('router.personalCenter'),
          hidden: true,
          canTo: true
        }
      }
    ]
  },

  {
    path: '/dashboard',
    component: Layout,
    name: 'Dashboard',
    meta: {
      alwaysShow: false
    },
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: t('router.dashboard'),
          icon: 'ant-design:dashboard-filled'
        }
      }
    ]
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/level',
    component: Layout,
    name: 'Level',
    meta: {
      title: '消息通知',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'Menujgyebztx',
        name: 'Menujgyebztx',
        component: () => import('@/views/Level/Menujgyebztx.vue'),
        meta: {
          title: '机构余额不足提醒'
        }
      },
      {
        path: 'Menudtjgbztx',
        name: 'Menudtjgbztx',
        component: () => import('@/views/Level/Menudtjgbztx.vue'),
        meta: {
          title: '当日机构条数不足提醒'
        }
      },
      {
        path: 'Menudcyebztx',
        name: 'Menudcyebztx',
        component: () => import('@/views/Level/Menudcyebztx.vue'),
        meta: {
          title: '产品方余额不足提醒'
        }
      },
      {
        path: 'Menuqdyebztx',
        name: 'Menuqdyebztx',
        component: () => import('@/views/Level/Menuqdyebztx.vue'),
        meta: {
          title: '渠道余额不足提醒'
        }
      },

      {
        path: 'MenuxyAPIcsyj',
        name: 'MenuxyAPIcsyj',
        component: () => import('@/views/Level/MenuxyAPIcsyj.vue'),
        meta: {
          title: '下游API超时预警'
        }
      },
      {
        path: 'Menuqdzhyj',
        name: 'Menuqdzhyj',
        component: () => import('@/views/Level/Menuqdzhyj.vue'),
        meta: {
          title: '渠道转化预警'
        }
      },
      {
        path: 'Menujgzhyj',
        name: 'Menujgzhyj',
        component: () => import('@/views/Level/Menujgzhyj.vue'),
        meta: {
          title: '机构转换预警'
        }
      },
      {
        path: 'Menufwqyjtz',
        name: 'Menufwqyjtz',
        component: () => import('@/views/Level/Menufwqyjtz.vue'),
        meta: {
          title: '预警设置'
        }
      }
    ]
  },
  {
    path: '/userForm',
    component: Layout,
    redirect: '/user/userForm',
    name: 'userForm',
    meta: {
      title: '用户管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'userjournal',
        name: 'userjournal',
        component: () => import('@/views/userManagement/userjournal.vue'),
        meta: {
          title: '用户管理'
        }
      },
      {
        path: 'userinfo',
        name: 'userinfo',
        component: () => import('@/views/userManagement/userinfo.vue'),
        meta: {
          title: '用户详情'
          // hidden: true
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/userManagement/user.vue'),
        meta: {
          title: '会员登录日志'
        }
      }
    ]
  },
  {
    path: '/channel',
    component: Layout,
    name: 'channel',
    meta: {
      title: '渠道管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'merchant',
        name: 'channelMerchant',
        component: () => import('@/views/Channel/merchant/index.vue'),
        meta: {
          title: '渠道商管理'
        }
      },
      {
        path: 'manager',
        name: 'channelManager',
        component: () => import('@/views/Channel/manager/index.vue'),
        meta: {
          title: '渠道管理'
        }
      },
      {
        path: 'managerSetup',
        name: 'channelManagerSetup',
        component: () => import('@/views/Channel/manager/index.vue'),
        meta: {
          title: '渠道管理',
          hidden: true
        }
      },
      {
        path: 'account',
        name: 'channelAccount',
        component: () => import('@/views/Channel/account/index.vue'),
        meta: {
          title: '渠道账号管理'
        }
      },
      {
        path: 'collision',
        name: 'channelCollision',
        component: () => import('@/views/Channel/collision/index.vue'),
        meta: {
          title: '渠道撞库记录'
        }
      },
      {
        path: 'statistics',
        name: 'channelStatistics',
        component: () => import('@/views/Channel/statistics/index.vue'),
        meta: {
          title: '渠道统计'
        }
      },
      {
        path: 'forms',
        name: 'channelForms',
        component: () => import('@/views/Channel/forms/index.vue'),
        meta: {
          title: '渠道报表'
        }
      },
      {
        path: 'collision-statistics',
        name: 'channelCollisionStatistics',
        component: () => import('@/views/Channel/collisionStatistics/index.vue'),
        meta: {
          title: '联登渠道撞库统计'
        }
      },
      {
        path: 'credit-statistics',
        name: 'channelCreditStatistics',
        component: () => import('@/views/Channel/creditStatistics/index.vue'),
        meta: {
          title: '全流程/授信回调渠道撞库统计'
        }
      },
      {
        path: 'achievement',
        name: 'channelAchievement',
        component: () => import('@/views/Channel/achievement/index.vue'),
        meta: {
          title: '渠道业绩报表'
        }
      },
      {
        path: 'analysis',
        name: 'channelAnalysis',
        component: () => import('@/views/Channel/analysis/index.vue'),
        meta: {
          title: '渠道分析'
        }
      },
      {
        path: 'city',
        name: 'channelCity',
        component: () => import('@/views/Channel/city/index.vue'),
        meta: {
          title: '城市进件分析'
        }
      },
      {
        path: 'h5',
        name: 'channelH5',
        component: () => import('@/views/Channel/h5/index.vue'),
        meta: {
          title: 'H5页面管理'
        }
      }
    ]
  },
  {
    path: '/Downstream',
    component: Layout,
    name: 'downstream',
    meta: {
      title: '下游机构管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'organizational',
        name: 'organizational',
        component: () => import('@/views/Downstream/organizational/organizational.vue'),
        meta: {
          title: '机构管理',
          permission: ['add', 'edit', 'refund', 'orgStatus', 'pushStatus', 'SmsStatus', 'recharge']
        }
      },
      {
        path: 'organAdd',
        name: 'organAdd',
        component: () => import('@/views/Downstream/organizational/organAdd.vue'),
        meta: {
          title: '机构新增/修改',
          noTagsView: true,
          hidden: true
        }
      },
      {
        path: 'Planmanagement',
        name: 'Planmanagement',
        component: () => import('@/views/Downstream/Planmanagement/Planmanagement.vue'),
        meta: {
          title: '方案管理',
          permission: [
            'add',
            'edit',
            'refund',
            'orgStatus',
            'planStatus',
            'SmsStatus',
            'pushStatus',
            'delete'
          ]
        }
      },
      {
        path: 'Planmanageadd',
        name: 'Planmanageadd',
        component: () => import('@/views/Downstream/Planmanagement/Planmanageadd.vue'),
        meta: {
          title: '方案添加/修改',
          hidden: true
        }
      },
      {
        path: 'qualifications',
        name: 'qualificationskey',
        component: () => import('@/views/Downstream/qualifications/qualifications.vue'),
        meta: {
          title: '机构资质审核'
        }
      },
      {
        path: 'Pushtions',
        name: 'Pushtions',
        component: () => import('@/views/Downstream/Pushtions/Pushtions.vue'),
        meta: {
          title: '推送记录(销售)',
          permission: ['Export']
        }
      },
      {
        path: 'leaderPushtions',
        name: 'leaderPushtions',
        component: () => import('@/views/Downstream/Pushtions/leaderPushtions.vue'),
        meta: {
          title: '推送记录(领导)',
          permission: ['Export']
        }
      },
      {
        path: 'Userrating',
        name: 'Userrating',
        component: () => import('@/views/Downstream/Userrating/Userrating.vue'),
        meta: {
          title: '机构用户评级统计',
          permission: ['Export']
        }
      },
      {
        path: 'InstitutionalStatistics',
        name: 'InstitutionalStatistics',
        component: () =>
          import('@/views/Downstream/InstitutionalStatistics/InstitutionalStatistics.vue'),
        meta: {
          title: '机构统计',
          permission: ['Export']
        }
      },
      {
        path: 'InstitutionalReports',
        name: 'InstitutionalReports',
        component: () => import('@/views/Downstream/InstitutionalReports/InstitutionalReports.vue'),
        meta: {
          title: '机构报表',
          permission: ['edit', 'add']
        }
      },
      {
        path: 'performance',
        name: 'performance',
        component: () => import('@/views/Downstream/performance/performance.vue'),
        meta: {
          title: '机构业绩报表'
        }
      }
    ]
  },
  {
    path: '/H5Statistics',
    component: Layout,
    redirect: '/product/H5Statistics',
    name: 'H5Statistics',
    meta: {
      title: 'H5统计',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'H5sRegistra',
        name: 'H5sRegistra',
        component: () => import('@/views/H5Statistics/Registration.vue'),
        meta: {
          title: '注册页统计'
        }
      },
      {
        path: 'H5FormPage',
        name: 'H5FormPage',
        component: () => import('@/views/H5Statistics/FormPageStatistics.vue'),
        meta: {
          title: '表单页统计'
        }
      },
      {
        path: 'H5togrant',
        name: 'H5togrant',
        component: () => import('@/views/H5Statistics/togrant.vue'),
        meta: {
          title: '授权页统计'
        }
      },
      {
        path: 'ResultOage',
        name: 'ResultOage',
        component: () => import('@/views/H5Statistics/ResultOage.vue'),
        meta: {
          title: '结果页统计'
        }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    redirect: '/product/side',
    name: 'product',
    meta: {
      title: '下游产品管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'side',
        name: 'productSide',
        component: () => import('@/views/Product/side/index.vue'),
        meta: {
          title: '产品方管理'
        }
      },
      {
        path: 'manager',
        name: 'productManager',
        component: () => import('@/views/Product/manager/index.vue'),
        meta: {
          title: '产品管理'
        }
      },
      {
        path: 'qualification-review',
        name: 'productQualificationReview',
        component: () => import('@/views/Product/qualificationReview/index.vue'),
        meta: {
          title: '产品方资质审核'
        }
      },
      {
        path: 'push',
        name: 'productPush',
        component: () => import('@/views/Product/push/index.vue'),
        meta: {
          title: '产品点击记录'
        }
      },
      {
        path: 'statistics',
        name: 'productStatistics',
        component: () => import('@/views/Product/statistics/index.vue'),
        meta: {
          title: '产品统计'
        }
      },
      {
        path: 'forms',
        name: 'productForms',
        component: () => import('@/views/Product/forms/index.vue'),
        meta: {
          title: '产品报表'
        }
      }
    ]
  },
  {
    path: '/system',
    component: Layout,
    redirect: '/system/approval',
    name: 'system',
    meta: {
      title: '系统管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'approval',
        name: 'systemApproval',
        component: () => import('@/views/System/approval/index.vue'),
        meta: {
          title: '审批流程配置'
        }
      },
      {
        path: 'push',
        name: 'systemPush',
        component: () => import('@/views/System/push/index.vue'),
        meta: {
          title: '推送设置'
        }
      },
      {
        path: 'disable-ip',
        name: 'systemDisableIP',
        component: () => import('@/views/System/disable-ip/index.vue'),
        meta: {
          title: '禁用IP'
        }
      },
      {
        path: 'settings',
        name: 'systemSettings',
        component: () => import('@/views/System/settings/index.vue'),
        meta: {
          title: '系统设置'
        }
      },
      {
        path: 'blacklist',
        name: 'systemBlacklist',
        component: () => import('@/views/System/blacklist/index.vue'),
        meta: {
          title: '黑名单'
        }
      },
      {
        path: 'oplog',
        name: 'systemOplog',
        component: () => import('@/views/System/oplog/index.vue'),
        meta: {
          title: '操作日志'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: Layout,
    name: 'finance',
    meta: {
      title: '财务管理',
      icon: 'carbon:skill-level-advanced',
      alwaysShow: true
    },
    children: [
      {
        path: 'summary',
        name: 'financeSummary',
        component: () => import('@/views/Finance/summary/index.vue'),
        meta: {
          title: '财务汇总统计',
          permission: ['export']
        }
      },
      {
        path: 'payment',
        name: 'financePayment',
        component: () => import('@/views/Finance/payment/index.vue'),
        meta: {
          title: '财务支付渠道统计',
          permission: ['export']
        }
      },
      {
        path: 'day-channels',
        name: 'financeDayChannels',
        component: () => import('@/views/Finance/dayChannels/index.vue'),
        meta: {
          title: '每日渠道财务统计',
          permission: ['export']
        }
      },
      {
        path: 'statistics',
        name: 'financeStatistics',
        component: () => import('@/views/Finance/statistics/index.vue'),
        meta: {
          title: '渠道财务统计',
          permission: ['export']
        }
      },
      {
        path: 'statements',
        name: 'financeStatements',
        component: () => import('@/views/Finance/statements/index.vue'),
        meta: {
          title: '渠道财务流水',
          permission: ['export']
        }
      },
      {
        path: 'daily-institutions',
        name: 'financeDailyInstitutions',
        component: () => import('@/views/Finance/dailyInstitutions/index.vue'),
        meta: {
          title: '每日机构财务统计',
          permission: ['export']
        }
      },
      {
        path: 'institutional-statistics',
        name: 'financeInstitutionalStatistics',
        component: () => import('@/views/Finance/institutionalStatistics/index.vue'),
        meta: {
          title: '机构财务统计',
          permission: ['export']
        }
      },
      {
        path: 'institutional-flow',
        name: 'financeInstitutionalFlow',
        component: () => import('@/views/Finance/institutionalFlow/index.vue'),
        meta: {
          title: '机构财务流水',
          permission: ['export']
        }
      },
      {
        path: 'product-day',
        name: 'financeProductDay',
        component: () => import('@/views/Finance/productDay/index.vue'),
        meta: {
          title: '每日产品财务统计',
          permission: ['export']
        }
      },
      {
        path: 'product-statistics',
        name: 'financeProductStatistics',
        component: () => import('@/views/Finance/productStatistics/index.vue'),
        meta: {
          title: '产品财务统计',
          permission: ['export']
        }
      },
      {
        path: 'product-flow',
        name: 'financeProductFlow',
        component: () => import('@/views/Finance/productFlow/index.vue'),
        meta: {
          title: '产品财务流水明细',
          permission: ['export']
        }
      },
      {
        path: 'institutional-recharge',
        name: 'institutionalRecharge',
        component: () => import('@/views/Finance/institutional/index.vue'),
        meta: {
          title: '机构充值审批',
          permission: ['export']
        }
      },
      {
        path: 'institutional-gift',
        name: 'institutionalGift',
        component: () => import('@/views/Finance/institutional/index.vue'),
        meta: {
          title: '机构赠送审批',
          permission: ['export']
        }
      },
      {
        path: 'institutional-refund',
        name: 'institutionalRefund',
        component: () => import('@/views/Finance/institutional/index.vue'),
        meta: {
          title: '机构退款审批',
          permission: ['export']
        }
      },
      {
        path: 'institutional-side-recharge',
        name: 'institutionalSideRecharge',
        component: () => import('@/views/Finance/side/index.vue'),
        meta: {
          title: '产品方充值审批',
          permission: ['export']
        }
      },
      {
        path: 'institutional-side-gift',
        name: 'institutionalSideGift',
        component: () => import('@/views/Finance/side/index.vue'),
        meta: {
          title: '产品方赠送审批',
          permission: ['export']
        }
      },
      {
        path: 'institutional-side-refund',
        name: 'institutionalSideRefund',
        component: () => import('@/views/Finance/side/index.vue'),
        meta: {
          title: '产品方退款审批',
          permission: ['export']
        }
      },
      {
        path: 'pay-approval',
        name: 'financePayApproval',
        component: () => import('@/views/Finance/refundApproval/index.vue'),
        meta: {
          title: '渠道付款审批',
          permission: ['export']
        }
      },
      {
        path: 'refund-approval',
        name: 'financeRefundApproval',
        component: () => import('@/views/Finance/refundApproval/index.vue'),
        meta: {
          title: '渠道退款审批',
          permission: ['export']
        }
      }
    ]
  },
  {
    path: '/legal-affairs',
    component: Layout,
    name: 'legal-affairs',
    meta: {
      title: '法务管理',
      icon: 'carbon:skill-level-advanced',
      alwaysShow: true
    },
    children: [
      {
        path: 'LA',
        name: 'LA',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '个人信息收集与共享授权书'
        }
      },
      {
        path: 'registration',
        name: 'legalAffairsRegistration',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '注册协议'
        }
      },
      {
        path: 'service',
        name: 'legalAffairsService',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '服务协议'
        }
      },
      {
        path: 'information',
        name: 'legalAffairsInformation',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '个人信息协议'
        }
      },
      {
        path: 'authorization',
        name: 'legalAffairsAuthorization',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '授权协议'
        }
      },
      {
        path: 'business',
        name: 'legalAffairsBusiness',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '业务知情同意书'
        }
      },
      {
        path: 'user',
        name: 'legalAffairsUser',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '用户隐私协议'
        }
      },
      {
        path: 'prompt',
        name: 'legalAffairsPrompt',
        component: () => import('@/views/LegalAffairs/index.vue'),
        meta: {
          title: '提示信息'
        }
      }
    ]
  },
  // end
  {
    path: '/error',
    component: Layout,
    redirect: '/error/404',
    name: 'Error',
    meta: {
      title: 'Error page',
      icon: 'ci:error',
      alwaysShow: true,
      hidden: true
    },
    children: [
      {
        path: '404-demo',
        component: () => import('@/views/Error/404.vue'),
        name: '404Demo',
        meta: {
          title: '404'
        }
      },
      {
        path: '403-demo',
        component: () => import('@/views/Error/403.vue'),
        name: '403Demo',
        meta: {
          title: '403'
        }
      },
      {
        path: '500-demo',
        component: () => import('@/views/Error/500.vue'),
        name: '500Demo',
        meta: {
          title: '500'
        }
      }
    ]
  },
  {
    path: '/authorization',
    component: Layout,
    redirect: '/authorization/user',
    name: 'Authorization',
    meta: {
      title: '权限管理',
      icon: 'eos-icons:role-binding',
      alwaysShow: true
    },
    children: [
      {
        path: 'department',
        component: () => import('@/views/Authorization/Department/Department.vue'),
        name: 'department',
        meta: {
          title: '组织架构'
        }
      },
      {
        path: 'role',
        component: () => import('@/views/Authorization/Role/Role.vue'),
        name: 'Role',
        meta: {
          title: '角色管理'
        }
      },
      {
        path: 'Roleadd',
        component: () => import('@/views/Authorization/Role/Roleadd.vue'),
        name: 'Roleadd',
        meta: {
          hidden: true
        }
      },
      {
        path: 'user',
        component: () => import('@/views/Authorization/User/User.vue'),
        name: 'User',
        meta: {
          title: '员工管理'
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/Authorization/Menu/Menu.vue'),
        name: 'Menu',
        meta: {
          title: '菜单管理'
        }
      }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})
export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
